
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { trimValues } from '@/application/utils/trim.js'
import { sendContact, sendRestorePassword } from '@/application/services/auth.js'
import { getLocalizedServerError } from '@/application/utils/localization'
import { useReCaptcha } from 'vue-recaptcha-v3'

export function useLoginContactForm() {
  const vueRouter = useRouter()
  const route = vueRouter.currentRoute.value
  const queryEmail = route.query.email
  const i18n = useI18n()
  const authType = ref('')
  const codeRequestData = ref({})
  const isDisabled = ref(false)
  const errorMessage = ref('')

  const AUTH_OTP_TYPE = 1
  const AUTH_PASSWORD_TYPE = 2

  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

  const formSchema = computed(() => [
    {
      label: i18n.t('signinMessage'),
      name: 'contact',
      as: 'input',
      type: 'text',
      rules: 'required|email',
      cols: ' col-12',
      inputClass: 'form-control',
    },
  ])
  const submitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mb-3 mt-4',
    text: i18n.t('continue'),
  }))
  const submitRestore = async(data) => {
    isDisabled.value = true
    const formatted = trimValues(data)

    await recaptchaLoaded()
    const token = await executeRecaptcha('restore_password_contact')
    formatted.recaptcha_token = token

    sendRestorePassword(formatted)
      .then(({ data }) => {
        codeRequestData.value.contact = data.contact
        isDisabled.value = false
        authType.value = data.authorization_type
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.signUp.errors.', err.response)
        isDisabled.value = false
      })
  }
  const submit = async(data) => {
    isDisabled.value = true
    const formatted = trimValues(data)

    await recaptchaLoaded()
    const token = await executeRecaptcha('login_enter_contact')
    formatted.recaptcha_token = token

    sendContact(formatted)
      .then(({ data }) => {
        codeRequestData.value.contact = data.contact
        isDisabled.value = false
        authType.value = data.authorization_type
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.signUp.errors.', err.response)
        isDisabled.value = false
      })
  }
  return {
    formSchema,
    submitButton,
    submit,
    submitRestore,
    codeRequestData,
    authType,
    isDisabled,
    errorMessage,
    AUTH_OTP_TYPE,
    AUTH_PASSWORD_TYPE,
    queryEmail,
  }
}
