<template>
  <h3 class="mb-4 text-center">{{ $t('forms.restorePassword.title') }}</h3>
  <p v-if="!showOtp" class="mb-3 text-center">{{ $t('forms.restorePassword.description') }}</p>
  <div class="form-group mb-3">
    <template v-if="!showOtp">
      <DynamicForm
        :schema="formSchema"
        :button-data="restorePasswordSubmitButton"
        :is-disabled="contactDisabled"
        form-class="justify-content-center"
        @submit="submitRestore"
      >
        <template v-slot:error>
          <div class="server-error-message content mt-3" v-if="contactErrorMessage.length">
            {{ contactErrorMessage }}
            <button @click.prevent="contactErrorMessage = ''" class="feather icon-x button"></button>
          </div>
        </template>
      </DynamicForm>
      <p class="mb-4 text-muted text-center">
        <router-link :to="{ name: 'sign-in' }" class="license-preview">{{$t('restorePasswordBackLoginProposal')}}</router-link>
      </p>
    </template>
    <p class="mb-3 text-center" v-if="showOtp">{{ $t('codeDescription') }}</p>
    <DynamicForm
      v-if="showOtp"
      :schema="codeFormSchema"
      :button-data="restorePasswordCodeSubmitButton"
      :is-disabled="codeDisabled"
      form-class="justify-content-center"
      @submit="submitCode"
    >
      <template v-slot:error>
        <div class="server-error-message content mt-3" v-if="codeErrorMessage.length">
          {{ codeErrorMessage }}
          <button @click.prevent="codeErrorMessage = ''" class="feather icon-x button"></button>
        </div>
      </template>
    </DynamicForm>
  </div>
</template>

<script>
import { computed } from 'vue'
import DynamicForm from './DynamicForm.vue'
import { useLoginContactForm } from '@/application/composables/loginContactForm.js'
import { useLoginCodeForm } from '@/application/composables/loginCodeForm.js'
import { useRestorePasswordForm } from '@/application/composables/restorePasswordForm.js'

export default {
  name: 'RestorePasswordForm',
  components: {
    DynamicForm,
  },
  setup() {
    const {
      formSchema,
      isDisabled: contactDisabled,
      codeRequestData,
      submitRestore,
      authType,
      AUTH_OTP_TYPE,
      errorMessage: contactErrorMessage,
    } = useLoginContactForm()

    const {
      restorePasswordSubmitButton,
      restorePasswordCodeSubmitButton,
    } = useRestorePasswordForm(codeRequestData.value)

    const {
      codeFormSchema,
      submitCode,
      errorMessage: codeErrorMessage,
      isDisabled: codeDisabled,
    } = useLoginCodeForm(codeRequestData.value)

    const showOtp = computed(() => authType.value === AUTH_OTP_TYPE)

    return {
      formSchema,
      codeFormSchema,
      contactDisabled,
      codeDisabled,
      submitCode,
      contactErrorMessage,
      codeErrorMessage,
      restorePasswordSubmitButton,
      restorePasswordCodeSubmitButton,
      submitRestore,
      showOtp,
    }
  },
}
</script>
