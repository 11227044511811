<template>
  <div class="auth-wrapper aut-bg-img">
    <div class="auth-content">
      <div class="card">
        <div class="card-body">
          <div class="mb-4 text-center">
            <i class="feather icon-unlock auth-icon"></i>
          </div>
          <restore-password-form></restore-password-form>
          <div class="row justify-content-center">
            <language-element></language-element>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestorePasswordForm from '@/application/components/forms/RestorePasswordForm.vue'
import { AVAILABLE_LANGUAGES } from '@/application/localization/index.js'
import { useChangeLanguage } from '@/application/composables/changeLanguage.js'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'RestorePasswordPage',
  components: {
    RestorePasswordForm,
    'language-element': defineAsyncComponent(() => import('@/application/components/elements/LanguageElement.vue')),
  },
  setup() {
    const languages = AVAILABLE_LANGUAGES
    const { changeLanguageValue } = useChangeLanguage()
    return {
      languages,
      changeLanguageValue,
    }
  },
}
</script>
