import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useRestorePasswordForm(additionalData) {
  const i18n = useI18n()
  const isDisabled = ref(false)
  const errorMessage = ref('')

  const restorePasswordSubmitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mb-3 mt-4',
    text: i18n.t('forms.restorePassword.submit'),
  }))

  const restorePasswordCodeSubmitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mb-3 mt-4',
    text: i18n.t('forms.restorePassword.submitCode'),
  }))

  return {
    restorePasswordSubmitButton,
    restorePasswordCodeSubmitButton,
    errorMessage,
    isDisabled,
  }
}
