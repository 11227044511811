import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { trimValues } from '@/application/utils/trim.js'
import { sendCode } from '@/application/services/auth.js'
import { getCookie, setCookie } from '@/application/utils/cookie.js'
import { getLocalizedServerError } from '@/application/utils/localization'
import { trackLogin } from '@/application/utils/tracking-scripts'

export function useLoginCodeForm(additionalData) {
  const i18n = useI18n()
  const store = useStore()
  const router = useRouter()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const codeFormSchema = computed(() => [
    {
      label: i18n.t('code'),
      name: 'otp',
      as: 'input',
      type: 'text',
      rules: 'required|min:4|max:4|alpha_num|uppercase',
      cols: 'col-12',
      inputClass: 'form-control',
    },
  ])
  const codeSubmitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('logIn'),
  }))
  const submitCode = (data) => {
    isDisabled.value = true
    data.contact = additionalData.contact
    data.secret_key = additionalData.secret_key
    data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const formatted = trimValues(data)
    sendCode(formatted)
      .then(({ data }) => {
        setCookie(data)
        isDisabled.value = false
        store.commit('changeAuthStatus', getCookie('session_token'))
        trackLogin()
        router.push({
          name: 'add-password',
        })
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.signUp.errors.', err.response)
        isDisabled.value = false
      })
  }

  return {
    codeFormSchema,
    codeSubmitButton,
    submitCode,
    errorMessage,
    isDisabled,
  }
}
